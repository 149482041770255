import { Logo } from './components/Logo';
import { Room } from './components/Room';
import { useQuery } from "convex/react";
import { useMutation } from "convex/react";
import { api } from "./convex/_generated/api";
import { useConvexAuth } from "convex/react";
import { Authenticated, Unauthenticated } from "convex/react";
import { SignInButton } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import useStoreUserEffect from "./useStoreUserEffect.ts";
import { useState, useEffect } from 'react';
import './styles/App.css';
import * as styles from "./styles/Components.module.css"
import tichuLogo from "./res/tichu_logo.png"
import { TichuGame } from './components/TichuGame';

function App() {
  const rooms = useQuery(api.rooms.get);
  const createRoom = useMutation(api.rooms.createRoom);
  const joinRoom = useMutation(api.users.joinRoom);
  const leaveRoom = useMutation(api.users.leaveRoom);
  const updateScore = useMutation(api.rooms.updateScore);
  const { user } = useUser();
  const userId = useStoreUserEffect();
  const [selectedRoomId, setSelectedRoomId] = useState(undefined) ;
  const { isAuthenticated } = useConvexAuth();
  const room = useQuery(api.rooms.getRoom, {_id : selectedRoomId});
  // const users = useQuery(api.users.getUser, {_id : "jh72znf61w5t6ay46gds7frj356jvfjk"});

  useEffect(() => {
    setSelectedRoomId(selectedRoomId);
  }, [selectedRoomId]);

  if (room?.user0_id && room?.user1_id && room?.user2_id && room?.user3_id && !room?.gameOver) {
    return <TichuGame room={room} updateScore={updateScore}/>;
  }

  return (
    <div className="App-body">
      <div className={styles.signInContainer}>
      <Unauthenticated>
        <SignInButton className={styles.signInButton} mode="modal"/>
      </Unauthenticated>
      <Authenticated>
        <UserButton afterSignOutUrl="/"/>
      </Authenticated>
      </div>
        <div className={styles.enteringSceneContainer}>
          <div>
          <Logo/>
          <Room rooms={rooms} joinRoom={joinRoom} leaveRoom={leaveRoom} selectedRoomId={selectedRoomId} setSelectedRoomId={setSelectedRoomId} userId={userId} isAuthenticated={isAuthenticated} />
          </div>
      </div>
      {selectedRoomId ?
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      :
      <div id="createRoom" onClick={() => isAuthenticated ? createRoom({name: user?.firstName ? user?.firstName : "Secret", pictureUrl: user?.hasImage && user?.imageUrl ? user?.imageUrl : tichuLogo, user0_id : userId}) : document.querySelector('button[class*="Components_signInButton"]')?.click()} className={styles.createRoomContainer} title="Create Room">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="#103fef" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
        </svg>
      </div>
      }
    </div>
  );
}

export default App;
