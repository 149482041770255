import { Component } from "react";
import * as styles from "../styles/Components.module.css"

export class Room extends Component {
    state = {
        deleteRoom : false
    };

    joinRoom = (room_id) => {
        this.props.joinRoom({room_id: room_id});
        this.props.setSelectedRoomId(room_id);
    }

    leaveRoom = (room_id) => {
        this.setState({deleteRoom : true});
        this.props.leaveRoom({room_id: room_id});
        this.props.setSelectedRoomId(undefined);
        setTimeout(() => {
            this.setState({deleteRoom : false});
        }, 300);
    }

    componentDidUpdate() {
        let alreadySelectedRoom = false;
        for (let j = 0; j < this.props.rooms?.length; j++) {
            let room = this.props.rooms[j];
            for (let i = 0; i < 4; i++) {
                if (!room["gameOver"] && room["user" + i + "_id"] !== undefined) {
                    if (room["user" + i + "_id"] === this.props.userId) {
                        if (!this.state.deleteRoom) {
                            this.props.setSelectedRoomId(room._id);
                        }
                        alreadySelectedRoom = true;
                        break;
                    }
                }
            }
            if (alreadySelectedRoom) {
                break;
            }
        }
        if (this.props.selectedRoomId !== undefined) {
            this.joinRoom(this.props.selectedRoomId);
        }
    }

    render() {
        return (
            <div className={styles.roomContainer}>
                {this.props.rooms?.map(room =>
                    {
                        if (!room.gameOver) {
                            let users = 0;
                            for (let i = 0; i < 4; i++) {
                                if (room["user" + i + "_id"] !== undefined) {
                                    users++;
                                }
                            }
                            switch (users) {
                                case 1:
                                    return(
                                        <div className={styles.room} key={room._id}>
                                            <img src={room.pictureUrl} className={styles.avatar}/>
                                            <div>
                                                {room.name} Room
                                            </div>
                                            <div className={styles.oneUserIcon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="#d3d3d3" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                                                </svg>
                                            </div>
                                            {this.props.selectedRoomId === room._id ?
                                            <div onClick={() => this.leaveRoom(room._id)} className={styles.leaveRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path fill="#d3d3d3" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                                </svg>
                                            </div>
                                            : this.props.selectedRoomId === undefined ?
                                            <div onClick={() => this.props.isAuthenticated ? this.joinRoom(room._id) : document.querySelector('button[class*="Components_signInButton"]')?.click()} className={styles.joinRoomButton} style={this.state.deleteRoom ? {visibility: 'hidden'} : {}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="#d3d3d3" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                                </svg>
                                            </div>
                                            :
                                            <div className={styles.inactiveJoinRoomButton}>
                                                <div className={styles.ldsFacebook}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    );
                                case 2:
                                    return(
                                        <div className={styles.room} key={room._id}>
                                            <img src={room.pictureUrl} className={styles.avatar}/>
                                            <div>
                                                {room.name} Room
                                            </div>
                                            <div className={styles.twoUsersIcon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                    <path fill="#d3d3d3" d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/>
                                                </svg>
                                            </div>
                                            {this.props.selectedRoomId === room._id ?
                                            <div onClick={() => this.leaveRoom(room._id)} className={styles.leaveRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path fill="#d3d3d3" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                                </svg>
                                            </div>
                                            : this.props.selectedRoomId === undefined ?
                                            <div onClick={() => this.props.isAuthenticated ? this.joinRoom(room._id) : document.querySelector('button[class*="Components_signInButton"]')?.click()} className={styles.joinRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="#d3d3d3" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                                </svg>
                                            </div>
                                            :
                                            <div className={styles.inactiveJoinRoomButton}>
                                                <div className={styles.ldsFacebook}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    );
                                case 3:
                                    return(
                                        <div className={styles.room} key={room._id}>
                                            <img src={room.pictureUrl} className={styles.avatar}/>
                                            <div>
                                                {room.name} Room
                                            </div>
                                            <div className={styles.threeUsersIcon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                    <path fill="#d3d3d3" d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/>
                                                </svg>
                                            </div>
                                            {this.props.selectedRoomId === room._id ?
                                            <div onClick={() => this.leaveRoom(room._id)} className={styles.leaveRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path fill="#d3d3d3" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                                </svg>
                                            </div>
                                            : this.props.selectedRoomId === undefined ?
                                            <div onClick={() => this.props.isAuthenticated ? this.joinRoom(room._id) : document.querySelector('button[class*="Components_signInButton"]')?.click()} className={styles.joinRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="#d3d3d3" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                                </svg>
                                            </div>
                                            :
                                            <div className={styles.inactiveJoinRoomButton}>
                                                <div className={styles.ldsFacebook}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    );
                                case 4:
                                    return(
                                        <div className={styles.room} key={room._id}>
                                            <img src={room.pictureUrl} className={styles.avatar}/>
                                            <div>
                                                {room.name} Room
                                            </div>
                                            <div className={styles.fourUsersIcon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                    <path fill="#d3d3d3" d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                                                </svg>
                                            </div>
                                            {this.props.selectedRoomId === room._id ?
                                            <div onClick={() => this.leaveRoom(room._id)} className={styles.leaveRoomButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path fill="#d3d3d3" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                                                </svg>
                                            </div>
                                            : this.props.selectedRoomId === undefined ?
                                            <div className={styles.fullRoomButton}>
                                                Full
                                            </div>
                                            :
                                            <div className={styles.inactiveJoinRoomButton}>
                                                <div className={styles.ldsFacebook}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    );
                                default:
                                    return({});
                            }
                        }
                    }
                )}
            </div>
        );
    }
}
