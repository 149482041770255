import { Component } from "react";
import '../styles/Holo.css';

export class Logo extends Component {
    render() {
        return (
            <section className="cards" onClick={() => document.getElementById('createRoom')?.click()}>
                <div className="card charizard animated"></div>
            </section>
        );
    }
}